import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../fonts/fonts.css';
import logoImage from '../images/logo.png';
// import logoWhite from '../images/logoWhite.png'; // Import the white logo
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGlobe, faCalendarAlt, faComments } from '@fortawesome/free-solid-svg-icons';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #636363;
  transition: color 0.3s ease;
  position: relative;
  padding: 0 10px;

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 12px);
    background-color: rgba(169, 169, 169, 0.2);
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
    color: black;
  }
`;

const ActionButton = styled.button`
  background-color: #0A0A0A;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px; // This creates the "pill" shape
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const HeroHeading = styled.h1`
  font-size: 4rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600; // Inter Semibold
  color: black;
  margin: 0; // Remove default margin
  padding: 10px 0; // Add minimal padding for spacing
  margin-bottom: 10px;
`;

const HeroSubheading = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500; // Inter Semibold
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh; // Full viewport height
  background: #ffffff;
  padding: 0; // Remove all padding
  box-sizing: border-box; // Ensures padding is included in the height calculation
`;


const HeroButton = styled.button`
  padding: 12px 32px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: ${({ primary }) => (primary ? '#fff' : '#333')};
  background-color: ${({ primary }) => (primary ? '#007aff' : '#fff')};
  border: 2px solid ${({ primary }) => (primary ? '#007aff' : '#ddd')};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
`;

const FeaturesHeading = styled.h2`
  font-size: 2rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 40px;
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column; /* Ensures the heading is on top of the boxes */
  align-items: center; /* Centers the content horizontally */
  padding: 60px 20px;
  background: white;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const FeaturesGrid = styled.div`
  display: flex; /* Change grid to flexbox for row layout */
  flex-wrap: wrap; /* Ensures responsiveness by wrapping boxes to the next row on smaller screens */
  gap: 30px;
  justify-content: center; /* Centers the boxes horizontally */
  margin-top: 20px; /* Adds spacing between the heading and boxes */
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
`;

const FeatureCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Footer = styled.footer`
  background: #ffffff;
  padding: 40px 5%; // Increased the height of the footer
  color: #333;
  display: flex;
  justify-content: space-between; // Keep content in a row
  align-items: center;
  gap: 50px;
`;

// const FooterLogoSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
// `;

// const FooterLogo = styled.img`
//   height: 40px; /* Scaled size for logo */
//   width: auto;
// `;

const Slogan = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  color: #666; /* Gray text */
  margin-top: 8px;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 50px;
`;

const FooterLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const FooterHeader = styled.h4`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #333;
`;

const FooterLink = styled.a`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  color: #888; /* Light gray color */
  text-decoration: none;

  &:hover {
    color: #000; /* Darker text on hover */
  }
`;

const Divider = styled.div`
  background-color: transparent; /* Set the background to transparent */
  border: 0.75px solid #e6e6e6; /* Keep the border gray */
  width: 90%;
  margin: 20px auto;
`;

const Copyright = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 0.8rem;
  color: #888;
  text-align: center;
  margin-top: 10px;
`;

const SocialMediaIcons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const SocialMediaIcon = styled.a`
  color: #888; /* Gray color for icons */
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    color: black; /* Orange hover color */
    transform: scale(1.1);
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007aff;
  color: white;
  border-radius: 50%;
  border: none;
  width: 50px; /* Reduced size */
  height: 50px; /* Reduced size */
  font-size: 22px; /* Adjusted size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;

  /* Show button with a smooth transition */
  &.visible {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  &:hover {
    background-color: #005bb5;
    transform: scale(1.1);
  }
`;


const Landing = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) { // Show the button after scrolling down 300px
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleGetStarted = () => {
    navigate('/signup');
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <Logo src={logoImage} alt="Travel Local Logo" />
        <NavLinks>
          <NavLink href="#features">Features</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#contact">Contact</NavLink>
        </NavLinks>
        <ActionButton onClick={handleGetStarted}>Get Started</ActionButton>
      </HeaderContainer>

      <HeroSection>
        <HeroHeading>
          Travel Like a <span>Local</span>, Connect with Ease
        </HeroHeading>
        <HeroSubheading>
          Travel smarter and connect with locals for personalized advice and unique experiences.
        </HeroSubheading>
        {/* <InputContainer>
          <RoundedInput type="text" placeholder="I want to go to" />
          <BlueButton>Search</BlueButton>
        </InputContainer> */}
        <ButtonGroup>
          <HeroButton primary onClick={handleGetStarted}>
            Get Started
          </HeroButton>
          <HeroButton>Learn More</HeroButton>
        </ButtonGroup>
      </HeroSection>

      <FeaturesSection id="features">
        <FeaturesHeading>Why Choose Travel Local?</FeaturesHeading>
        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>🌍</FeatureIcon>
            <FeatureTitle>Global Network</FeatureTitle>
            <FeatureDescription>
              Connect with locals in over 100 countries for unique insights.
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>📅</FeatureIcon>
            <FeatureTitle>Flexible Scheduling</FeatureTitle>
            <FeatureDescription>
              Plan your trips and meet locals on your own schedule.
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>💬</FeatureIcon>
            <FeatureTitle>Real-Time Communication</FeatureTitle>
            <FeatureDescription>
              Get instant answers and tips from locals before your trip.
            </FeatureDescription>
          </FeatureCard>
          </FeaturesGrid>
      </FeaturesSection>


  <Divider />
  <Footer>

  {/* Middle Section: Slogan */}
  <div>
    <Slogan>Connect. Explore. Experience Travel Your Way.</Slogan>
  </div>


  {/* Middle Section: Social Media Icons */}
  <SocialMediaIcons>
    <SocialMediaIcon href="https://facebook.com" target="_blank">
      <i className="fab fa-facebook"></i>
    </SocialMediaIcon>
    <SocialMediaIcon href="https://twitter.com" target="_blank">
      <i className="fab fa-twitter"></i>
    </SocialMediaIcon>
    <SocialMediaIcon href="https://linkedin.com" target="_blank">
      <i className="fab fa-linkedin"></i>
    </SocialMediaIcon>
    <SocialMediaIcon href="https://instagram.com" target="_blank">
      <i className="fab fa-instagram"></i>
    </SocialMediaIcon>
    <SocialMediaIcon href="https://tiktok.com" target="_blank">
      <i className="fab fa-tiktok"></i>
    </SocialMediaIcon>
  </SocialMediaIcons>

  {/* Right Section: Footer Links */}
  <FooterLinks>
    <FooterLinkColumn>
      <FooterHeader>Resources</FooterHeader>
      <FooterLink href="localhost:3000/">Guide</FooterLink>
      <FooterLink href="#">Affiliate</FooterLink>
      <FooterLink href="#">FAQs</FooterLink>
    </FooterLinkColumn>
    <FooterLinkColumn>
      <FooterHeader>Company</FooterHeader>
      <FooterLink href="#">Careers</FooterLink>
      <FooterLink href="#">Support</FooterLink>
    </FooterLinkColumn>
    <FooterLinkColumn>
      <FooterHeader>Socials</FooterHeader>
      <FooterLink href="#">X (Twitter)</FooterLink>
      <FooterLink href="#">TikTok</FooterLink>
      <FooterLink href="#">Instagram</FooterLink>
      <FooterLink href="#">LinkedIn</FooterLink>
    </FooterLinkColumn>
    <FooterLinkColumn>
      <FooterHeader>Legal</FooterHeader>
      <FooterLink href="#">Terms</FooterLink>
      <FooterLink href="#">Privacy</FooterLink>
    </FooterLinkColumn>
  </FooterLinks>
</Footer>

<Divider />
<Copyright>&copy; 2025 TravelLocal. All rights reserved.</Copyright>
<Divider/>

      {/* Back to Top Button */}
      <BackToTopButton
        className={showButton ? 'visible' : ''}
        onClick={handleBackToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </BackToTopButton>

    </PageContainer>
  );
};

export default Landing;

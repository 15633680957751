import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logOut } from '../../firebaseConfig';
import LogoImage from '../../images/logo.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  padding: 2rem;
`;

const ContentContainer = styled.div`
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PageButton = styled.button`
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  font-family: 'Inter', sans-serif;
  
  &:hover, &:focus {
    transform: scale(1.05);
  }
`;

const PlusButton = styled(PageButton)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 9999px;
  font-size: 0.9rem;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #c82333;
  }
`;

const OneLineInputField = styled.input`
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  width: 100%;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
`;

const DestinationRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  max-width: 450px;
  margin-top: 1.2rem;
`;

const TopContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  width: calc(100% - 4rem);
`;

const Logo = styled.img`
  height: 150px;
  width: auto;
  cursor: pointer;
  transition: transform 0.4s;

  &:hover {
    transform: scale(1.07);
  }
`;

const PlanTripTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const PlanTripDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #6c757d;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  line-height: 0.9;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s, box-shadow 0.4s;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  }
`;

const InfoText = styled.span`
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupBox = styled.div`
  background: #fff;
  padding: 3rem 5rem; /* Reduced padding for better spacing */
  border-radius: 8px;
  width: 90%; /* Make it occupy 90% of the screen width */
  max-width: 600px; /* Allow more text to fit comfortably */
  text-align: left; /* Align text to the left */
  position: relative;
`;

const PopupHeader = styled.h2`
  margin-bottom: 1rem;
`;

const PopupText = styled.p`
  font-size: 1rem;
  color: #333;
  text-align: justify; /* Ensure text spans the full width */
  line-height: 1.4; /* Better line spacing for readability */
  margin: 0; /* Remove default margins */
  width: 100%; /* Ensures text uses the full width of the container */
  padding: 0; /* Remove unnecessary padding */
`;

const CloseButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px; /* Slightly increased padding */
  font-size: 1rem; /* Subtle increase in font size */
  border: none;
  border-radius: 6px; /* Slightly smoother edges */
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const PlanTrip = () => {
//   const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [tripDetails, setTripDetails] = useState({
    destinations: [''],
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleAddDestination = () => {
    setTripDetails({
      ...tripDetails,
      destinations: [...tripDetails.destinations, ''],
    });
  };

  const handleRemoveDestination = (index) => {
    const updatedDestinations = tripDetails.destinations.filter((_, i) => i !== index);
    setTripDetails({ ...tripDetails, destinations: updatedDestinations });
  };

  const handleDestinationChange = (index, value) => {
    const updatedDestinations = [...tripDetails.destinations];
    updatedDestinations[index] = value;
    setTripDetails({ ...tripDetails, destinations: updatedDestinations });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <PageContainer>
      <TopContainer>
        <Logo src={LogoImage} alt="TravelMate Logo" />
        <PageButton onClick={logOut}>Sign Out</PageButton>
      </TopContainer>

      <ContentContainer>
        {step === 0 && (
          <Card>
            <PlanTripTitle>Where are you going?</PlanTripTitle>
            <PlanTripDescription>Enter information for <strong>ONE </strong>country only.</PlanTripDescription>
            <PlanTripDescription><strong>Format:</strong> City, State/Region, Country</PlanTripDescription>
            <InputContainer>
              {tripDetails.destinations.map((destination, index) => (
                <DestinationRow key={index}>
                  <OneLineInputField
                    type="text"
                    placeholder={`Destination ${index + 1}`}
                    value={destination}
                    onChange={(e) => handleDestinationChange(index, e.target.value)}
                  />
                  {index > 0 && (
                    <DeleteButton onClick={() => handleRemoveDestination(index)}>Delete</DeleteButton>
                  )}
                </DestinationRow>
              ))}
            </InputContainer>
            <PlusButton onClick={handleAddDestination}>+</PlusButton>
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
              <br></br>
            </ButtonContainer>
            <br></br>
            <PlanTripDescription>
              <InfoText onClick={() => setShowPopup(true)}>Information</InfoText>
            </PlanTripDescription>
          </Card>
        )}
      </ContentContainer>

      {showPopup && (
        <PopupContainer>
          <PopupBox>
            <PopupHeader>Multiple Destinations</PopupHeader>
            <PopupText>
              You can enter multiple destinations in the format 'City, State/Region, Country'. 
              State is required for all U.S. destinations.
              <br></br> <br></br>
              Each destination will be treated as a separate location. For example, 
              if you're visiting Paris, London, and New York, you can enter each of them separately.
              <br></br> <br></br>
              You must enter the details properly for your request to be accepted. You can add a country,
              but be warned that Locals may not have the knowledge of the entire country.
              <br></br> <br></br>
              Please do not add spots or certain places in the destinations, that is not what TravelLocal
              is meant for. If you don't know what are the best locations in a country to visit, please
              put the country name so you can receive information on that from a trusted Local.
            </PopupText>
            <CloseButton onClick={handlePopupClose}>Close</CloseButton>
          </PopupBox>
        </PopupContainer>
      )}
    </PageContainer>
  );
};

export default PlanTrip;
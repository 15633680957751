// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, updatePassword as firebaseUpdatePassword, deleteUser } from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Ensure modular imports for Firestore
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // Firebase Storage Bucket
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Optional: Initialize Firebase Analytics (if needed)
getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Google Auth provider
const googleProvider = new GoogleAuthProvider();

// Google Sign-In function
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      // If the user doesn't exist, create a new document with default fields
      await setDoc(userDocRef, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        role: 'Travelers', // Default role, can be changed later
        completeSetup: false // Default setup completion status
      });
    } else {
      // Optionally, update user document with the latest info
      await setDoc(userDocRef, {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email
      }, { merge: true });
    }

    return user;
  } catch (error) {
    console.error('Error signing in with Google:', error);
    throw error;
  }
};

// Log out function
const logOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

// Create a new user with email and password
const createUser = async (email, password, role = 'Travelers') => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Add user data to Firestore with role
    const userDocRef = doc(db, 'users', user.uid);
    await setDoc(userDocRef, {
      email: user.email,
      role: role, // Role provided while creating the user
      completeSetup: false // Default setup completion status
    });

    return user;
  } catch (error) {
    console.error('Error creating user:', error.message);
    throw error;
  }
};

// Sign in with email and password
const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in with email:', error);
    throw error;
  }
};

// Fetch user role from Firestore by UID
const getUserRole = async (uid) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    
    if (userDoc.exists()) {
      return userDoc.data().role;
    }
    return null;
  } catch (error) {
    console.error('Error fetching user role:', error);
    throw error;
  }
};

// Update Firestore user profile
const updateUserProfile = async (uid, data) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    await updateDoc(userDocRef, data);
  } catch (error) {
    console.error('Error updating Firestore user document:', error);
    throw error;
  }
};

// Upload user avatar to Firebase Storage
const uploadAvatar = async (file, userId) => {
  try {
    const storageRef = ref(storage, `avatars/${userId}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading avatar:', error);
    throw error;
  }
};

// Update user password
const updatePassword = async (newPassword) => {
  const user = auth.currentUser;
  if (user) {
    try {
      await firebaseUpdatePassword(user, newPassword);
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  } else {
    throw new Error('No user is signed in.');
  }
};

// Delete user account
const deleteUserAccount = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      await deleteUser(user);
    } catch (error) {
      console.error('Error deleting user account:', error);
      throw error;
    }
  } else {
    throw new Error('No user is signed in.');
  }
};

// Fetch all users by role (for example, fetch all "Travelers")
const fetchUsersByRole = async (role) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('role', '==', role));
    const querySnapshot = await getDocs(q);

    const users = [];
    querySnapshot.forEach(doc => {
      users.push(doc.data());
    });

    return users;
  } catch (error) {
    console.error('Error fetching users by role:', error);
    throw error;
  }
};

export { 
  auth, 
  db, 
  signInWithGoogle, 
  logOut, 
  createUser, 
  signInWithEmail, 
  getUserRole, 
  updateUserProfile, 
  uploadAvatar, 
  updatePassword, 
  deleteUserAccount, 
  fetchUsersByRole, 
  storage // Add storage here
};
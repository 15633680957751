import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { db, auth } from '../../firebaseConfig';
import { logOut } from '../../firebaseConfig';
import LogoImage from '../../images/logo.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  padding: 2rem;
`;

const ContentContainer = styled.div`
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PageButton = styled.button`
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  font-family: 'Inter', sans-serif;
  
  &:hover, &:focus {
    transform: scale(1.05);
  }
`;

const SignOutButton = styled(PageButton)`
  background-color: #dc3545;
  padding: 0.7rem 1.4rem;
  font-size: 1rem;
  &:hover, &:focus {
    background-color: #c82333;
  }
`;


const Card = styled.div`
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s, box-shadow 0.4s;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  max-width: 450px;
  margin-top: 1.2rem;
`;

const Button = styled.button`
  background-color: #ffffff;
  color: #007bff;
  font-size: 1rem;
  padding: 12px 24px;
  border: 2px solid #007bff;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  font-family: 'Inter', sans-serif;

  &:hover, &:focus {
    transform: scale(1.05);
  }

  &.selected {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
  }
`;

const OneLineInputField = styled.input`
  padding: 1rem;
  border: 1px solid ${props => (props.isInvalid ? '#dc3545' : '#ced4da')};
  border-radius: 8px;
  margin-bottom: 1.2rem;
  width: 100%;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// const HorizontalButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 1.5rem;
//   margin-top: 2rem;
// `;

const TopContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  width: calc(100% - 4rem);
`;

const Logo = styled.img`
  height: 150px;
  width: auto;
  cursor: pointer;
  transition: transform 0.4s;
  
  &:hover {
    transform: scale(1.07);
  }
`;

const CardTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
`;

const TravelersTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const CardDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

const ProgressBar = styled.div`
  width: 100%;
  max-width: 100%;  // Ensure progress bar doesn't overflow
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const Progress = styled.div`
  height: 100%;
  width: ${props => Math.min(props.progress, 100)}%;  // Ensure width doesn't exceed 100%
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.5s ease;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  margin-top: 3rem;
`;

const Slogan = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #aaa;
  margin-bottom: 1rem; /* Add spacing below the slogan */
`;

const Copyright = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 0.5rem; /* Adjust gap between slogan and copyright */
`;

const DropdownSelect = styled.select`
  width: 80%;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #f1f1f1;
  border: 1px solid #007bff;
  color: #007bff;
  font-family: 'Inter', sans-serif;
  option {
    color: #007bff;
  }
`;

const SliderInput = styled.input`
  width: 80%;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #f1f1f1;
  border: 1px solid #007bff;
  font-family: 'Inter', sans-serif;
`;

const Onboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(14); // Step 1: Welcome
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedConnectionMethods, setSelectedConnectionMethods] = useState([]);
  const [budget, setBudget] = useState(25);
  const [currency, setCurrency] = useState("USD");

  const handleNextStep = () => {
    if (step < 8) {
      setStep(step + 1);
      setProgress(progress + 14);  // Increment progress
    } else if (step === 8) {
      handleComplete();
    }
  };

  const handleInterestSelection = (interest) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter(i => i !== interest));
    } else {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  const handleConnectionMethodSelection = (method) => {
    if (selectedConnectionMethods.includes(method)) {
      setSelectedConnectionMethods(selectedConnectionMethods.filter(m => m !== method));
    } else {
      setSelectedConnectionMethods([...selectedConnectionMethods, method]);
    }
  };

  const handleCurrencyChange = (selectedCurrency) => {
    setCurrency(selectedCurrency);
    
    // Set the budget range based on the selected currency
    let minValue, maxValue;
    
    switch (selectedCurrency) {
      case 'INR':
        minValue = 50;
        maxValue = 4000;
        break;
      case 'USD':
        minValue = 15;
        maxValue = 1000;
        break;
      case 'EUR':
        minValue = 15;
        maxValue = 1000;
        break;
      case 'GBP':
        minValue = 5;
        maxValue = 500;
        break;
      default:
        minValue = 15;
        maxValue = 1000;
    }
  
    // Reset the budget to the minimum for the selected currency
    setBudget(minValue);

    if(setBudget === maxValue)
    {
        console.log("");
    }
    
    // Optionally, you can update the range for the slider input
    // You can use minValue and maxValue here to adjust the slider's range
  };
  
  const handleComplete = () => {
    setProgress(100);  // Set the progress to 100%
    setStep(9);  // Move to the next step, which will show the completion screen
  };;

  return (
    <PageContainer>
      <TopContainer>
        <Logo src={LogoImage} alt="TravelMate Logo" />
        <SignOutButton onClick={logOut}>Sign Out</SignOutButton>
      </TopContainer>
      <ContentContainer>
        <ProgressBar>
          <Progress progress={progress} />
        </ProgressBar>

        {/* Step 1: Welcome */}
        {step === 1 && (
          <Card>
            <TravelersTitle>Welcome, Traveler!</TravelersTitle>
            <CardDescription>
              Let’s tailor your experience and connect you with Locals who can make your trip unforgettable.
            </CardDescription>
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Get Started</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 2: Name and Personal Details */}
        {step === 2 && (
          <Card>
            <CardTitle>Your Name & Profile Picture</CardTitle>
            <OneLineInputField placeholder="Full Name" />
            <OneLineInputField type="file" placeholder="Upload Profile Picture" />
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 3: Where Are You Going */}
        {step === 3 && (
          <Card>
            <CardTitle>Let's Plan Your First Trip</CardTitle>
            <OneLineInputField placeholder="Destination (e.g., Paris, France)" />
            <OneLineInputField type="date" placeholder="Travel Dates" />
            <OneLineInputField placeholder="Purpose of Trip (e.g., Leisure)" />
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
              <p style={{ color: 'red', cursor: 'pointer' }} onClick={handleNextStep}>Do this later</p>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 4: Tell Us What You Enjoy */}
        {step === 4 && (
          <Card>
            <CardTitle>Tell Us What You Enjoy</CardTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {['🍽 Food Tours', '🎨 Art & Culture', '🌄 Adventure', '🏖 Relaxing', '🛶 Outdoor Activities'].map(interest => (
                <Button
                  key={interest}
                  onClick={() => handleInterestSelection(interest)}
                  className={selectedInterests.includes(interest) ? 'selected' : ''}
                >
                  {interest}
                </Button>
              ))}
              <Button>Add Your Own Interests</Button>
            </div>
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 5: Cultural & Language Preferences */}
        {step === 5 && (
          <Card>
            <CardTitle>Language</CardTitle>
            <OneLineInputField placeholder="Languages You Speak" />
            <OneLineInputField placeholder="Languages You Understand" />
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 6: Budget */}
        {step === 6 && (
          <Card>
            <CardTitle>Your Budget Per Day</CardTitle>
            <SliderInput
                type="range"
                min={currency === 'INR' ? 50 : currency === 'GBP' ? 5 : 15}
                max={currency === 'INR' ? 4000 : currency === 'GBP' ? 500 : 1000}
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            <p>Budget: {currency} {budget}</p>
            <DropdownSelect value={currency} onChange={(e) => handleCurrencyChange(e.target.value)}>
              <option value="USD">USD</option>
              <option value="INR">INR</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              {/* Add other currencies here */}
            </DropdownSelect>
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {/* Step 7: How Would You Like To Connect? */}
        {step === 7 && (
          <Card>
            <CardTitle>How Would You Like To Connect with Locals?</CardTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {['💬 Text chat', '📞 Voice/Video calls', '👋 In-person meetup'].map(method => (
                <Button
                  key={method}
                  onClick={() => handleConnectionMethodSelection(method)}
                  className={selectedConnectionMethods.includes(method) ? 'selected' : ''}
                >
                  {method}
                </Button>
              ))}
            </div>
            <ButtonContainer>
              <PageButton onClick={handleNextStep}>Next</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {step === 8 && (
          <Card>
            <CardTitle>Almost Done!</CardTitle>
            <CardDescription>
              You’ve completed all the steps. Click below to finalize!
            </CardDescription>
            <ButtonContainer>
              <PageButton onClick={handleComplete}>Complete</PageButton>
            </ButtonContainer>
          </Card>
        )}

        {step === 9 && (
          <Card>
            <ProgressBar>
              <Progress progress={100} style={{ backgroundColor: 'green' }} />
            </ProgressBar>
            <p>100% complete</p>
            <ButtonContainer>
              <PageButton onClick={() => navigate('/travelers/dashboard')}>Continue</PageButton>
            </ButtonContainer>
          </Card>
        )} 

        {/* Footer */}
        <FooterContent>
        <Slogan>Connect. Explore. Experience Travel Your Way.</Slogan>
        <Copyright>© 2025 TravelLocal. All rights reserved.</Copyright>
    </FooterContent>
      </ContentContainer>
    </PageContainer>
  );
};

export default Onboarding;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth, deleteUserAccount, updatePassword, db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

// Styled Components
const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #f8f9fa;
`;

const Sidebar = styled.div`
  width: 200px;
  min-width: 200px;
  background-color: white;
  color: black;
  padding-top: 2rem; /* Adjust padding to balance vertical space */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
`;

const Tab = styled.div`
  padding: 0.8rem 1.5rem; /* Adjust padding for a smaller size */
  text-align: center;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Inter', sans-serif;
  width: 100px; /* Set a fixed width for all tabs */
  font-weight: ${({ active }) => (active ? '600' : '400')}; /* Dynamically set font weight based on active prop */
  border-radius: 12px; /* Round the corners */
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 10px 15px; /* Added vertical gap */

  &:hover {
    background-color: #f0f0f0; /* Softer gray background on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
  }

  background-color: transparent;
  color: inherit;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Section = styled.div`
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #343a40;
`;

const UserBox = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  margin-bottom: 1rem;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border: 2px solid #e9ecef;
  cursor: pointer;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  margin-bottom: 1rem;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

// const DeleteButton = styled(Button)`
//   background-color: #e53935;
//   &:hover {
//     background-color: #c62828;
//   }
// `;

const DisplayNameSection = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
`;

const DisplayNameTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #212529;
  margin-bottom: 0.5rem;
`;

const DisplayNameDescription = styled.p`
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 1rem;
`;

const DisplayNameInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const DisplayNameFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
`;

const DeleteAccountSection = styled.div`
  background-color: #fff;
  padding: 2rem;
  border: 2px solid rgb(219, 97, 107);
  border-radius: 8px;
  box-shadow: none;
  font-family: 'Inter', sans-serif;
`;

const DeleteAccountTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #212529;
  margin-bottom: 1rem;
`;

const DeleteAccountDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  line-height: 1.5;
  margin-bottom: 2rem;
`;

const DeleteAccountButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background-color: #e53935;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }
`;


const SaveButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? '#e9ecef' : '#007bff')};
  color: ${({ disabled }) => (disabled ? '#6c757d' : '#fff')};
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#e9ecef' : '#0056b3')};
  }
`;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column; /* Stack tabs and button vertically */
//   align-items: center; /* Center them horizontally */
//   justify-content: center; /* Center vertically if needed */
//   width: 100%; /* Ensure it spans the available space */
//   margin: 0 auto; /* Center the container itself */
//   padding: 10px; /* Add some breathing room */
// `;

const BackButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  width: calc(100% - 20px);
  margin: 10px auto 0; /* Added vertical gap */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Settings = () => {
  const [userData, setUserData] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [role, setRole] = useState('');
  const [image, setImage] = useState(null);
  const [activeTab, setActiveTab] = useState('general');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const data = userDoc.data();
        setUserData(user);
        setProfilePicture(data.profilePicture || '');
        setDisplayName(data.displayName || '');
        setRole(data.role || '');
      }
    };

    fetchUserData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setImage(file);
    } else {
      alert('Only PNG and JPG images are allowed');
    }
  };

  if(image === setImage)
  {
    
  }

  // const handleUploadImage = async () => {
  //   if (!image) {
  //     alert('Please select an image first.');
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.readAsDataURL(image);
  //   reader.onloadend = async () => {
  //     const base64Image = reader.result;

  //     await updateDoc(doc(db, 'users', userData.uid), {
  //       profilePicture: base64Image,
  //     });

  //     setProfilePicture(base64Image);
  //     alert('Profile picture updated successfully!');
  //   };
  // };

  const handleSaveDisplayName = async () => {
    await updateDoc(doc(db, 'users', userData.uid), { displayName });
    alert('Display name updated successfully!');
  };

  const handleChangePassword = async () => {
    if (newPassword) {
      await updatePassword(newPassword);
      alert('Password changed successfully!');
    } else {
      alert('Please enter a new password.');
    }
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        setRole(userDoc.data().role);
      }
    };
    fetchUserRole();
  }, []);

  const handleBackButtonClick = () => {
    if (role === "Traveler") {
      navigate("/travelers/dashboard");
    } else if (role === "Local") {
      navigate("/locals/dashboard");
    }
  };
  

  return (
    <PageContainer>
      
      <Sidebar>
        <Tab active={activeTab === 'general'} onClick={() => setActiveTab('general')}>
          General
        </Tab>
        <Tab active={activeTab === 'account'} onClick={() => setActiveTab('account')}>
          Account
        </Tab>
        <BackButton onClick={handleBackButtonClick}>Back</BackButton>
      </Sidebar>
      <ContentContainer>
        {activeTab === 'general' && (
          <>
            <Section>
              <SectionTitle>User Information</SectionTitle>
              <UserBox>
                <div><strong>Username: </strong>{displayName || 'Your Name'}</div>
                <div><strong>Role: </strong>{role || 'Your Role'}</div>
              </UserBox>
            </Section>
            <Section>
  <SectionTitle>Avatar</SectionTitle>
  <AvatarContainer>
    <Avatar src={profilePicture || 'https://via.placeholder.com/100'} />
    <Button onClick={() => document.getElementById('uploadAvatar').click()}>
      Change Avatar
    </Button>
    <input
      type="file"
      style={{ display: 'none' }}
      id="uploadAvatar"
      onChange={handleImageChange}
    />
  </AvatarContainer>
</Section>
                  <Section>
        <DisplayNameSection>
          <DisplayNameTitle>Display Name</DisplayNameTitle>
          <DisplayNameDescription>
            Please enter your full name, or a display name you are comfortable with.
          </DisplayNameDescription>
          <DisplayNameInput
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter your display name"
          />
          <DisplayNameFooter>
            <span>Please use 32 characters at maximum.</span>
            <SaveButton disabled={!displayName.trim()} onClick={handleSaveDisplayName}>
              Save
            </SaveButton>
          </DisplayNameFooter>
        </DisplayNameSection>
      </Section>;
          </>
        )}
        {activeTab === 'account' && (
          <>
            <Section>
              <SectionTitle>Change Password</SectionTitle>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <Button onClick={handleChangePassword}>Change Password</Button>
            </Section>
            <Section as={DeleteAccountSection}>
                <DeleteAccountTitle>Delete Account</DeleteAccountTitle>
                <DeleteAccountDescription>
                  Permanently remove your Personal Account and all of its contents from the TravelLocal platform.
                  This action is not reversible, so please continue with caution.
                </DeleteAccountDescription>
                <DeleteAccountButton onClick={deleteUserAccount}>
                  Delete Personal Account
                </DeleteAccountButton>
              </Section>

          </>
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default Settings;
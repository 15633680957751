import React, { useState } from 'react';
import styled from 'styled-components';

const ActiveRequestsContainer = styled.div`
  font-family: 'Inter', sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  text-align: center;
`;

const RequestCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
`;

const ProfilePicture = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const RequestInfo = styled.div`
  font-size: 16px;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RequestDetails = styled.div`
  font-size: 14px;
  color: #777;
`;

const RequestMeta = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  display: flex;
  gap: 15px;
`;

const RequestActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: ${props => props.color || '#007bff'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: ${props => props.hoverColor || '#0056b3'};
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupBox = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  max-width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const PopupTitle = styled.h2`
  font-size: 20px;
  color: #333;
`;

const PopupContent = styled.p`
  font-size: 16px;
  color: #555;
`;

const CloseButton = styled.button`
  padding: 8px 16px;
  background-color: #ff3b3b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  &:hover {
    background-color: #e60000;
  }
`;

const ActiveRequests = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <ActiveRequestsContainer>
      <Title>Active Requests</Title>
      <RequestCard>
        <ProfilePicture src="https://via.placeholder.com/150" alt="John's profile" />
        <div style={{ flex: 1 }}>
          <RequestInfo>
            <span><strong>Travelers:</strong> Nimai Garg (23), Jane Doe (16)</span>
            <span><strong>Destination:</strong> Paris, France</span>
            <span><strong>Dates Traveling:</strong> March 15 - 22, 2025</span>
            <span><strong>MatchFactor:</strong> 8.7/10</span>
          </RequestInfo>
          <RequestDetails><strong>Request:</strong> Assistance with local attractions</RequestDetails>
          <RequestMeta>
            <span><strong>Location:</strong> Paris</span>
          </RequestMeta>
          <RequestActions>
            <Button color="#007bff" hoverColor="#0056b3" onClick={togglePopup}>Description</Button>
            <Button color="#28a745" hoverColor="#218838">Accept</Button>
            <Button color="#dc3545" hoverColor="#c82333">Decline</Button>
          </RequestActions>
        </div>
      </RequestCard>
      {showPopup && (
        <PopupContainer onClick={togglePopup}>
          <PopupBox onClick={(e) => e.stopPropagation()}>
            <PopupTitle>Traveler's Request Description</PopupTitle>
            <PopupContent>
              <strong>About the Trip:</strong> This trip is part of a family vacation to explore European landmarks.
              <br />
              <strong>Specific Request:</strong> We are looking for recommendations for museums and cultural
              experiences suitable for children aged 5-10.
            </PopupContent>
            <CloseButton onClick={togglePopup}>Close</CloseButton>
          </PopupBox>
        </PopupContainer>
      )}
      <RequestCard>
        <ProfilePicture src="https://via.placeholder.com/150" alt="Jane's profile" />
        <div style={{ flex: 1 }}>
          <RequestInfo>
            <span><strong>Travelers:</strong> Nimai Garg (23), Jane Smith (20)</span>
            <span><strong>Destination:</strong> New York, USA</span>
            <span><strong>Dates Traveling:</strong> February 10 - 15, 2025</span>
            <span><strong>MatchFactor:</strong> 9.5/10</span>
          </RequestInfo>
          <RequestDetails><strong>Request:</strong> Specialized help with transportation</RequestDetails>
          <RequestMeta>
            <span><strong>Location:</strong> New York City</span>
          </RequestMeta>
          <RequestActions>
            <Button color="#007bff" hoverColor="#0056b3" onClick={togglePopup}>Description</Button>
            <Button color="#28a745" hoverColor="#218838">Accept</Button>
            <Button color="#dc3545" hoverColor="#c82333">Decline</Button>
          </RequestActions>
        </div>
      </RequestCard>
    </ActiveRequestsContainer>
  );
};

export default ActiveRequests;
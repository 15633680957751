import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { db } from '../../firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, orderBy } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';

const PageWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7fc;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background: #0077ff;
  color: white;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 24px;
  margin-right: 16px;

  &:hover {
    color: #d0eaff;
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Sidebar = styled.div`
  width: 30%;
  background: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  overflow-y: auto;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  background: ${(props) => (props.isActive ? '#f0f0f0' : 'white')};
  transition: background 0.2s;

  &:hover {
    background: #f9f9f9;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProfilePicture = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${(props) =>
    `url(${props.src && props.src !== '' ? props.src : 'https://via.placeholder.com/50'})`} 
    no-repeat center/cover;
  margin-right: 15px;
`;

const ContactInfo = styled.div`
  flex: 1;
`;

const DisplayName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
`;

// const LatestMessage = styled.div`
//   font-size: 14px;
//   color: #777;
//   margin-top: 5px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

const Time = styled.div`
  font-size: 12px;
  color: #aaa;
  margin-left: 10px;
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  margin-bottom: 15px;
  padding: 12px 20px;
  max-width: 70%;
  background: ${(props) => (props.isOwnMessage ? '#0077ff' : '#f1f1f1')};
  color: ${(props) => (props.isOwnMessage ? '#fff' : '#333')};
  border-radius: 20px;
  align-self: ${(props) => (props.isOwnMessage ? 'flex-end' : 'flex-start')};
`;

const TimeStamp = styled.div`
  font-size: 12px;
  color: #aaa;
  margin-top: 5px;
  text-align: right;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  position: sticky;
  bottom: 0;
`;

const Input = styled.input`
  flex: 1;
  padding: 16px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-right: 12px;
`;

const Button = styled.button`
  padding: 14px 24px;
  font-size: 16px;
  color: #fff;
  background: #0077ff;
  border: none;
  border-radius: 30px;
`;

const EmojiButton = styled.button`
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 10px;
`;

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  right: 75%;
  z-index: 1000;
`;

const LocalsText = () => {
    const auth = getAuth();
    const userId = auth.currentUser ? auth.currentUser.uid : null;
  
    const [travelers, setTravelers] = useState([]);
    const [selectedTraveler, setSelectedTraveler] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  
    const messagesEndRef = useRef(null);
  
    useEffect(() => {
      const fetchTravelerUsers = async () => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('role', '==', 'Traveler'));
        onSnapshot(q, (querySnapshot) => {
          const users = querySnapshot.docs.map((doc) => {
            const userData = doc.data();
            return {
              id: doc.id,
              displayName: userData.displayName,
              role: userData.role,
              profilePicture: userData.profilePicture || '',
              latestMessage: userData.latestMessage || '',
              latestTime: userData.latestTime
                ? moment(userData.latestTime.toDate()).format('hh:mm A')
                : '',
            };
          });
          setTravelers(users);
        });
      };
  
      fetchTravelerUsers();
    }, []);
  
    useEffect(() => {
        if (selectedTraveler) {
          const messagesRef = collection(db, 'messages');
          const q = query(
            messagesRef,
            where('senderId', 'in', [userId, selectedTraveler.id]),
            where('receiverId', 'in', [userId, selectedTraveler.id]),
            orderBy('timestamp', 'asc') // Ensure messages are sorted by timestamp in ascending order
          );
      
          onSnapshot(q, (querySnapshot) => {
            const messages = querySnapshot.docs.map((doc) => doc.data());
            setMessages(messages);
            scrollToBottom();
          });
        }
      }, [selectedTraveler, userId]);
  
    const sendMessage = async () => {
      if (newMessage.trim()) {
        await addDoc(collection(db, 'messages'), {
          text: newMessage,
          senderId: userId,
          receiverId: selectedTraveler.id,
          timestamp: new Date(),
        });
        setNewMessage('');
        scrollToBottom();
      }
    };
  
    const handleEmojiClick = (emoji) => {
      console.log(emoji); // Log the emoji object to see its structure
      setNewMessage((prevMessage) => prevMessage + (emoji.emoji || emoji));
      setShowEmojiPicker(false);
    };
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
  
    return (
      <PageWrapper>
        <Header>
          <BackButton onClick={() => setSelectedTraveler(null)}>
            <FaArrowLeft />
          </BackButton>
          {selectedTraveler ? `Chatting with ${selectedTraveler.displayName}` : ''}
        </Header>
        <ChatWrapper>
          <Sidebar>
            {travelers.map((traveler) => (
              <Contact
                key={traveler.id}
                isActive={selectedTraveler?.id === traveler.id}
                onClick={() => setSelectedTraveler(traveler)}
              >
                <ContactDetails>
                  <ProfilePicture src={traveler.profilePicture} />
                  <ContactInfo>
                    <DisplayName>{traveler.displayName}</DisplayName>
                  </ContactInfo>
                </ContactDetails>
                <Time>{traveler.latestTime || ''}</Time>
              </Contact>
            ))}
          </Sidebar>
          <ChatArea>
          <MessagesContainer>
  {messages.map((msg, index) => (
    <Message key={index} isOwnMessage={msg.senderId === userId}>
      {msg.text}
      <TimeStamp>{moment(msg.timestamp?.toDate()).format('hh:mm A')}</TimeStamp>
    </Message>
  ))}
  <div ref={messagesEndRef} />
</MessagesContainer>
            {showEmojiPicker && (
              <EmojiPickerWrapper>
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </EmojiPickerWrapper>
            )}
            <InputContainer>
              <EmojiButton onClick={() => setShowEmojiPicker((prev) => !prev)}>😊</EmojiButton>
              <Input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
              />
              <Button onClick={sendMessage}>Send</Button>
            </InputContainer>
          </ChatArea>
        </ChatWrapper>
      </PageWrapper>
    );
  };

export default LocalsText;
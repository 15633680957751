import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logoImage from '../../images/logo.png';
import { logOut } from '../../firebaseConfig';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: white;
  padding: 0;
  overflow-x: hidden;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
    height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const ActionButton = styled.button`
  background-color: #007aff;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const SignOutButton = styled.button`
  background-color: red;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const DashboardSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Move content up */
  align-items: center;
  padding: 0px 20px 20px; /* Further reduced top padding */
  background: white;
  text-align: center;
`;


const Heading = styled.h1`
  font-size: 2.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
`;

const Subheading = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #555;
  margin-top: 10px;
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Reduced margin-top */
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const OptionButton = styled.button`
  color: #000000;
  background-color: transparent;
  border: 1.2px solid #D3D3D3; /* Lighter gray border */
  border-radius: 10px;
  padding: 60px 0px;
  width: 250px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;

  &:hover, &:focus {
    color: #000;
    border: 1.2px solid #B0B0B0; /* Darker gray on hover */
    transform: scale(1.05);
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  margin-top: 2rem; /* Reduced margin-top to push content up */
`;

const Slogan = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #aaa;
  margin-bottom: 1rem; /* Add spacing below the slogan */
`;

const Copyright = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 0.5rem; /* Adjust gap between slogan and copyright */
`;

const TravelerDashboard = () => {
  const navigate = useNavigate();

  const handleText = () => {
    navigate('/travelers/text');
  };

  const handleGetStarted = () => {
    navigate('/settings');
  };

  const handleCurrentTrips = () => {
    navigate('/travelers/current-trips');
  };

  const handleNewTrip = () => {
    navigate('/travelers/plan-a-new-trip');
  };

  const handleSignOut = async () => {
    try {
      navigate('/');
      await logOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <Logo src={logoImage} alt="Travel Local Logo" />
        <ButtonContainer>
          <ActionButton onClick={handleGetStarted}>Manage Profile</ActionButton>
          <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
        </ButtonContainer>
      </HeaderContainer>

      <DashboardSection>
        <Heading>Welcome to Your Dashboard</Heading>
        <Subheading>Access personalized travel experiences, manage your profile, and more.</Subheading>
      </DashboardSection>

      <ButtonContainer>
        <OptionButton onClick={handleCurrentTrips}>View your current trips</OptionButton>
        <OptionButton onClick={handleText}>Current Conversations</OptionButton>
        <OptionButton onClick={handleNewTrip}>Plan a new trip</OptionButton>
      </ButtonContainer>

      <FooterContent>
        <Slogan>Connect. Explore. Experience Travel Your Way.</Slogan>
        <Copyright>© 2025 TravelLocal. All rights reserved.</Copyright>
    </FooterContent>
    </PageContainer>
  );
};

export default TravelerDashboard;
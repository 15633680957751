import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, query, where, onSnapshot, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const TravelersText = () => {
  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const [locals, setLocals] = useState([]);
  const [selectedLocal, setSelectedLocal] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    console.log('User ID:', userId);
    console.log('Selected Local:', selectedLocal);
  }, [userId, selectedLocal]);

  useEffect(() => {
    const fetchLocalUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('role', '==', 'Local'));

        onSnapshot(q, (querySnapshot) => {
          const users = querySnapshot.docs.map((doc) => {
            const userData = doc.data();
            if (userData.role && userData.displayName) {
              return { id: doc.id, displayName: userData.displayName, role: userData.role };
            }
            return null;
          }).filter((user) => user !== null);
          setLocals(users);
        });
      } catch (error) {
        console.error('Error fetching locals:', error);
      }
    };

    fetchLocalUsers();
  }, []);

  useEffect(() => {
    if (selectedLocal && userId) {
      const messagesRef = collection(db, 'messages');
      const q = query(
        messagesRef,
        where('senderId', 'in', [userId, selectedLocal.id]),
        where('receiverId', 'in', [userId, selectedLocal.id])
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messagesList = querySnapshot.docs.map((doc) => doc.data());
        setMessages(messagesList);
      });

      return () => unsubscribe(); // Cleanup listener
    }
  }, [selectedLocal, userId]);

  const sendMessage = async () => {
    if (newMessage.trim() !== '' && userId && selectedLocal?.id) {
      try {
        await addDoc(collection(db, 'messages'), {
          senderId: userId,
          receiverId: selectedLocal.id,
          text: newMessage,
          timestamp: new Date(),
        });
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    } else {
      console.error('User ID or Selected Local ID is not set', userId, selectedLocal?.id);
    }
  };

  return (
    <div>
      <h1>Messages</h1>
      <select onChange={(e) => setSelectedLocal(locals.find(user => user.id === e.target.value))}>
        <option value="">Select a Local</option>
        {locals.map(user => (
          <option key={user.id} value={user.id}>
            {user.displayName}
          </option>
        ))}
      </select>

      <div>
        {messages.map((message, index) => (
          <div key={index}>
            <strong>{message.senderId === userId ? 'You' : selectedLocal?.displayName}:</strong>
            <p>{message.text}</p>
          </div>
        ))}
      </div>

      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message..."
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default TravelersText;
// File: TravelerDashboard.jsx (or whatever your Traveler Dashboard file is called)
import React, { useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const TravelerDashboard = () => {
  useEffect(() => {
    const testFirestore = async () => {
      try {
        const messagesRef = collection(db, "messages");
        const snapshot = await getDocs(messagesRef);
        snapshot.forEach((doc) => {
          console.log(doc.id, "=>", doc.data());
        });
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };

    testFirestore();
  }, []);

  return (
    <div>
      <h2>Traveler Dashboard</h2>
      <p>Check the console for Firestore test results.</p>
      {/* Other dashboard content here */}
    </div>
  );
};

export default TravelerDashboard;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { db, auth } from '../../firebaseConfig';
import { logOut } from '../../firebaseConfig';
import LogoImage from '../../images/logo.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  padding: 2rem;
`;

const ContentContainer = styled.div`
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PageButton = styled.button`
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  font-family: 'Inter', sans-serif;
  
  &:hover, &:focus {
    transform: scale(1.05);
  }
`;

const SignOutButton = styled(PageButton)`
  background-color: #dc3545;
  padding: 0.7rem 1.4rem;
  font-size: 1rem;
  &:hover, &:focus {
    background-color: #c82333;
  }
`;


const Card = styled.div`
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s, box-shadow 0.4s;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  max-width: 450px;
  margin-top: 1.2rem;
`;

const Button = styled.button`
  background-color: #ffffff;
  color: #007bff;
  font-size: 1rem;
  padding: 12px 24px;
  border: 2px solid #007bff;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  transition: transform 0.2s ease;
  font-family: 'Inter', sans-serif;

  &:hover, &:focus {
    transform: scale(1.05);
  }

  &.selected {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
  }
`;

const OneLineInputField = styled.input`
  padding: 1rem;
  border: 1px solid ${props => (props.isInvalid ? '#dc3545' : '#ced4da')};
  border-radius: 8px;
  margin-bottom: 1.2rem;
  width: 100%;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const TopContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  width: calc(100% - 4rem);
`;

const Logo = styled.img`
  height: 150px;
  width: auto;
  cursor: pointer;
  transition: transform 0.4s;
  
  &:hover {
    transform: scale(1.07);
  }
`;

const CardTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
`;

const LocalsTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const CardDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

const ProgressBar = styled.div`
  width: 100%;
  max-width: 100%;  // Ensure progress bar doesn't overflow
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const Progress = styled.div`
  height: 100%;
  width: ${props => Math.min(props.progress, 100)}%;  // Ensure width doesn't exceed 100%
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.5s ease;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  margin-top: 3rem;
`;

const Slogan = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #aaa;
  margin-bottom: 1rem; /* Add spacing below the slogan */
`;

const Copyright = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 0.5rem; /* Adjust gap between slogan and copyright */
`;

const Onboarding = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(14); // Step 1: Welcome
    const [selectedServices, setSelectedServices] = useState([]);
  
    const handleNextStep = () => {
      if (step < 8) {
        setStep(step + 1);
        setProgress(progress + 14); // Increment progress
      } else if (step === 8) {
        handleComplete();
      }
    };
  
    const handleServiceSelection = (service) => {
      if (selectedServices.includes(service)) {
        setSelectedServices(selectedServices.filter(s => s !== service));
      } else {
        setSelectedServices([...selectedServices, service]);
      }
    };
  
    const handleComplete = () => {
      setProgress(100); // Set progress to 100%
      setStep(9); // Move to the completion step
    };
  
    return (
      <PageContainer>
        <TopContainer>
          <Logo src={LogoImage} alt="TravelMate Logo" />
          <SignOutButton onClick={logOut}>Sign Out</SignOutButton>
        </TopContainer>
        <ContentContainer>
          <ProgressBar>
            <Progress progress={progress} />
          </ProgressBar>
  
          {/* Step 1: Welcome */}
          {step === 1 && (
            <Card>
              <LocalsTitle>Welcome, Local!</LocalsTitle>
              <CardDescription>
                Let’s help travelers make their trips unforgettable by sharing your local expertise.
              </CardDescription>
              <ButtonContainer>
                <PageButton onClick={handleNextStep}>Get Started</PageButton>
              </ButtonContainer>
            </Card>
          )}
  
          {/* Step 2: Name and Profile */}
          {step === 2 && (
            <Card>
              <CardTitle>Your Name & Profile Picture</CardTitle>
              <OneLineInputField placeholder="Full Name" />
              <OneLineInputField type="file" placeholder="Upload Profile Picture" />
              <ButtonContainer>
                <PageButton onClick={handleNextStep}>Next</PageButton>
              </ButtonContainer>
            </Card>
          )}
  
          {/* Step 3: Services You Offer */}
          {step === 3 && (
            <Card>
              <CardTitle>What Services Can You Provide?</CardTitle>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {['🚗 Guided Tours', '🍽 Food Recommendations', '🎨 Cultural Insights', '🌄 Adventure Planning', '🏠 Homestays'].map(service => (
                  <Button
                    key={service}
                    onClick={() => handleServiceSelection(service)}
                    className={selectedServices.includes(service) ? 'selected' : ''}
                  >
                    {service}
                  </Button>
                ))}
                <Button>Add Your Own Services</Button>
              </div>
              <ButtonContainer>
                <PageButton onClick={handleNextStep}>Next</PageButton>
              </ButtonContainer>
            </Card>
          )}
  
          {/* Additional Steps for Locals */}
          {/* Example Step 5: Availability */}
          {step === 4 && (
            <Card>
              <CardTitle>When Are You Available to Start?</CardTitle>
              <OneLineInputField type="date" placeholder="Available Dates" />
              {/* <OneLineInputField type="time" placeholder="Preferred Time Slots" /> */}
              <ButtonContainer>
                <PageButton onClick={handleNextStep}>Next</PageButton>
              </ButtonContainer>
            </Card>
          )}
  
          {/* Completion Screen */}
          {step === 5 && (
            <Card>
              <LocalsTitle>You're All Set!</LocalsTitle>
              <CardDescription>
                Thank you for joining TravelMate as a Local. Start connecting with Travelers now!
              </CardDescription>
              <ButtonContainer>
                <PageButton onClick={() => navigate('/locals/dashboard')}>Go to Dashboard</PageButton>
              </ButtonContainer>
            </Card>
          )}
        </ContentContainer>
        <FooterContent>
          <Slogan>Share. Connect. Make Travel Memorable.</Slogan>
          <Copyright>© 2025 TravelLocal. All rights reserved.</Copyright>
        </FooterContent>
      </PageContainer>
    );
  };
  
  export default Onboarding;